import { Link } from '@grandstand/presentation-models'
import { NextRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { ApiClient } from '../newPackages/ApiClient'
import currentUserStore from '../newPackages/StorageProviders/currentUserStore'
import { ConfigServiceContext, useContextUnconditionally } from '../services/config/ConfigService'
import { ErrorDetails, ErrorButton as VideoErrorButton } from '../type/Error'
import { isConnectedWeb } from '../utils/appUtils'
import { Logger } from '../utils/logger'
import { modifyDRMTokenIfApplicable } from '../utils/videoUtil'
import { getAdInfo, getDeviceIsSafari, getDeviceType } from './useDeviceInfo'
import { getIsDebug } from './useIsDebug'

export interface VideoSrc {
  videoId: string
  title: string
  src: string
  type: 'application/dash+xml' | 'application/x-mpegURL'
  isLive: boolean
  stream_type: 'linear' | 'vod'
  keySystems?: KeySystems
  heartbeat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  raw: VideoResponse
}

export interface Heartbeat {
  interval: number
  link: Link
}

// All props are marked as optional since the payload may change depending
// on video type.
export interface VideoAnalyticsForGoogle {
  away_team?: string
  broadcast_team?: string
  content_region?: string
  content_type?: string
  first_air_date?: string
  first_air_time?: string
  first_air_timestamp?: string
  game_id?: string
  game_type?: string
  home_team?: string
  league?: string
  material_id?: string
  stream_type?: string
  streaming_method?: string
  team?: string
  video_duration?: number
  video_id?: string
  video_platform?: string
  video_title?: string
  video_type?: string
}

export interface Analytics extends VideoAnalyticsForGoogle {
  /* */
}

export interface HeartbeatResponse {
  message: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

export interface VideoResponse {
  url: string
  title: string
  description: string
  islive: boolean
  stream_type: 'linear' | 'vod'
  drm?: DRM
  heart_beat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  start_date: string
  end_date: string
  next_id?: string
}

// ERROR types + classes
export type ButtonSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type ButtonStyle = 'primary' | 'alt' | 'on-image' | 'live'

// .video types

export type VideoState = {
  title: string
  url: string
  src: VideoSrc
  isLive: boolean
  keySystems?: KeySystems
  heartbeat?: Heartbeat
}
// .video DRM types
export interface DRM {
  licenseUrl: string
  token: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

// heartbeat
export interface Heartbeat {
  interval: number
  link: Link
}

export interface HeartbeatResponse {
  message: string
}

export type VideoServiceProps = {
  videoId: string
  useRouter: () => NextRouter
  isDisabled?: boolean
}

export class VideoServiceError {
  status: number
  message: string
  code: string
  constructor({ message, status, code }: { message: string; status: number; code: string }) {
    this.message = message
    this.status = status
    this.code = code
  }
}

// Reducer for VideoService.state

export type DRMType = 'widevine' | 'playready' | 'fairplay'
export type DRMFormat = 'DASH' | 'HLS'

export type VideoService = {
  src: VideoSrc | null
  error: ErrorDetails | null
}

export const useVideoService = ({ videoId, useRouter, isDisabled }: VideoServiceProps): VideoService => {
  Logger.of('useVideoService').info('init', { videoId, isDisabled })
  const router = useRouter()

  const { currentConfig, apiEnvironment, nativeAppVersion } = useContextUnconditionally(ConfigServiceContext)
  const [src, setSrc] = useState<VideoSrc | null>(null)
  const [error, setError] = useState<ErrorDetails | null>(null)

  useEffect(() => {
    if (error) {
      return
    }
    const getVideo = async () => {
      const currentUser = currentUserStore.getItem()
      Logger.of('useVideoService.getVideo').info('Getting video', { videoId })
      const configUrl = currentConfig.API.services.video_services.playback
      if (configUrl === undefined) {
        return
      }
      const adInfo = await getAdInfo(nativeAppVersion)
      const isSafari = getDeviceIsSafari()

      const getUrl = (): string => {
        const baseUrl = `${configUrl}/${videoId}`

        const deviceType = getDeviceType()
        let drmType: DRMType = 'widevine'
        let format: string = 'DASH'
        switch (deviceType) {
          case 'tv_xboxone':
            Logger.of('useVideoService.getVideo').debug('Identified device as XBox', { videoId })
            drmType = 'playready'
            break
          case 'web_browser':
            Logger.of('useVideoService.getVideo').debug('Identified device as browser', { videoId })
            if (isSafari) {
              Logger.of('useVideoService.getVideo').debug('Identified device as Safari', { videoId })
              format = 'HLS'
              drmType = 'fairplay'
            }
            break
        }

        const params: { [key: string]: any } = {
          drmType,
          format,
          appversion: nativeAppVersion,
          debug: getIsDebug(),
          ...adInfo,
        }

        const favTeams = currentUser?.profile.favorites.teams

        if (favTeams && favTeams.length > 0) {
          params.favteams = favTeams.join(',')
        }

        if (isConnectedWeb()) {
          params.chromecast = true
        }
        if (params.did === '') {
          delete params.did
        }

        // convert params to string
        const paramsString = new URLSearchParams(params).toString()
        const url = `${baseUrl}?${paramsString}`
        Logger.of('useVideoService.getVideo').debug('params', { params, paramsString, url, videoId })
        return `${baseUrl}?${paramsString}`
      }
      const url = getUrl()
      try {
        const res = await ApiClient.convenientApiFetch({
          url,
          method: 'GET',
        })

        if (res.ok) {
          const body = (await res.json()) as VideoResponse

          const getKeySystems = async (): Promise<KeySystems> => {
            if (body?.drm === undefined) {
              Logger.of('useVideoService.getVideo').error('drm not found', { videoId })
              return {}
            }
            const drm = body.drm
            drm.token = await modifyDRMTokenIfApplicable(`${drm.token}`)
            var headers = {
              'x-dt-auth-token': drm.token,
            }
            return {
              'com.widevine.alpha': {
                url: drm.licenseUrl,
                licenseHeaders: headers,
              },
              'com.apple.fps.1_0': {
                getContentId: function (emeOptions: any, initData: any) {
                  return initData
                },
                getCertificate: async function (emeOptions: any, callback: (e: any, c: any) => void) {
                  let url = new URL(drm.licenseUrl)
                  url.pathname += 'cert/ballysports'
                  const res = await fetch(url.toString(), {
                    method: 'GET',
                    headers: {
                      ...headers,
                    },
                  })
                  const responseBody = await res.arrayBuffer()
                  callback(null, new Uint8Array(responseBody))
                },
                getLicense: async function (
                  emeOptions: any,
                  contentId: any,
                  keyMessage: any,
                  callback: (e: any, c: any) => void
                ) {
                  const res = await fetch(drm.licenseUrl, {
                    method: 'POST',
                    body: keyMessage,
                    headers: {
                      ...headers,
                    },
                  })
                  const responseBody = await res.text()
                  let rawLicenseString = atob(responseBody)
                  let data = new Uint8Array(rawLicenseString.length)
                  for (let i = 0; i < rawLicenseString.length; ++i) {
                    data[i] = rawLicenseString.charCodeAt(i)
                  }
                  let key = data.buffer
                  callback(null, key)
                },
              },
            }
          }

          // formats are DASH or hls_v3
          const keySystems = await getKeySystems()
          // formats are DASH or hls_v3
          const nextSrc: VideoSrc = {
            videoId,
            raw: body,
            title: body.title,
            type: isSafari ? 'application/x-mpegURL' : 'application/dash+xml',
            src: body.url,
            keySystems: keySystems,
            heartbeat: body.heart_beat,
            analytics: body.analytics,
            conviva_analytics: body.conviva_analytics,
            isLive: body.islive,
            stream_type: body.stream_type,
          }
          Logger.of('useVideoService.getVideo').debug('Setting video source', { videoId, nextSrc })
          setSrc(nextSrc)
          setError(null)
        } else {
          const error = await res.json()
          Logger.of('useVideoService.getVideo').error('Video service error', { videoId, error })
          setError(error)
          setSrc(null)
        }
      } catch (error) {
        Logger.of('useVideoService.getVideo').error('Video fetch error', { error, url, videoId })
        setError({ status: 0, code: 'network_error', title: 'network error', message: 'network error', severity: 0 })
      }
    }

    // get video
    getVideo()
    return () => {
      /* */
    }
  }, [currentConfig.API.services.video_services.playback, videoId, error, apiEnvironment, nativeAppVersion])

  useEffect(() => {
    const heartbeat = src?.heartbeat
    if (error || !heartbeat || isDisabled) {
      return () => {
        /* */
      }
    }
    const url = heartbeat.link.url

    const getHeartbeat = async () => {
      if (error) {
        return
      }
      try {
        const res = await ApiClient.convenientApiFetch({
          method: 'POST',
          url,
        })
        if (res.ok) {
          // Heartbeat successful!
          return
        }
        const error = await res.json()
        Logger.of('useVideoService.getHeartbeat').error('Video service error', { videoId, error })
        setError({
          code: 'unknown_heartbeat_error', // will be overwritten if code present
          ...error,
        })
        return
      } catch (e) {
        console.error(e)
      }
    }

    getHeartbeat()

    // get heartbeat every ms
    const ms = heartbeat?.interval ?? 500
    const heartbeatInterval = setInterval(() => {
      getHeartbeat()
    }, ms)
    return () => {
      clearInterval(heartbeatInterval)
    }
  }, [error, videoId, src?.heartbeat, isDisabled])

  return {
    src,
    error,
  }
}
export { ErrorDetails, VideoErrorButton }
